<template>
  <div>
    <VCupertino
      v-if="isCreateOrder3dProjectOpen"
      id="dialog-dialog-order-3d-project"
      :is-show="isCreateOrder3dProjectOpen"
      :drawer-options="{
        modal: $viewport.isGreaterOrEquals('md'),
        cssClass:'dialog-create-order cupertino-modal',
        fitHeight: true
      }"

      @backdropTap="onCloseDialog"
      @didDismiss="onCloseDialog"
      @willPresent="willPresent"
    >
      <div class="dialog-create-order__body">
        <div class="dialog-create-order__title h2">Заказать 3Д проект</div>
        <div class="dialog-create-order__form">
          <div class="--form-element">
            <input
              v-model="formValues.name"
              type="text"
              class="input"
              placeholder="Ваше имя *"
            />
            <div
              v-for="(error) in ($v?.name?.$errors || [])"
              class="--form-element__error"
            >
              {{ error.$message }}
            </div>
          </div>
          <div class="--form-element">
            <input
              v-model="formValues.phone"
              v-maska
              data-maska="+7(F##) ###-##-##"
              data-maska-tokens="F:[0,3,4,9]"
              type="text"
              class="input"
              placeholder="+7(___) ___-__-__"
            />
            <div
              v-for="(error) in ($v?.phone?.$errors || [])"
              class="--form-element__error"
            >
              {{ error.$message }}
            </div>
          </div>
          <div class="--form-element">
            <input
              v-model="formValues.email"
              type="text"
              class="input"
              placeholder="E-mail *"
            />
            <div
              v-for="(error) in ($v?.email?.$errors || [])"
              class="--form-element__error"
            >
              {{ error.$message }}
            </div>
          </div>
          <div class="--form-element">
            <textarea
              v-model="formValues.message"
              class="input textarea"
              placeholder="Комментарий к заказу 3Д проекта *"
            />
            <div
              v-for="(error) in ($v?.message?.$errors || [])"
              class="--form-element__error"
            >
              {{ error.$message }}
            </div>
          </div>
        </div>
        <div class="dialog-create-order__images">
          <FormImages
            label="Добавьте фотографии вашей комнаты"
            :images="formValues.file"
            @onChange="changeFormImages"
          />
        </div>
        <div class="dialog-create-order__action">
          <button
            class="btn btn-primary btn-big"
            :class="{'--loading': isSending}"
            :disabled="isSending"
            @click="submitForm"
          >
            {{ isSending ? 'Секундочку пожалуйста ...' : 'Отправить заявку' }}
          </button>
          <div class="--message h6">
            Перед отправкой ознакомьтесь, пожалуйста,<br />
            <a
              href="/policy"
              target="_blank"
              class="hover-decoration"
            >с политикой конфиденциальности.</a>
          </div>
        </div>
      </div>
    </VCupertino>
  </div>
</template>

<script setup lang="ts">
import { email, helpers, minLength, required } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import { useCreateOrder3dProject } from '~/composables/useCreateOrder3dProject';
import { useCommonStore } from '~/store/common';
import { useNotificationModal } from '~/composables/useNotificationModal';
import FormImages from '~/components/widget/FormImages.vue';

const VCupertino = defineAsyncComponent(() => import('~/components/widget/VCupertino.vue'));

const {
  $api,
  $toast,
  $viewport,
  $ym
} = useNuxtApp();
const {
  isCreateOrder3dProjectOpen,
  closeCreateOrder3dProjectOpen
} = useCreateOrder3dProject();
const {
  changeLoading
} = useCommonStore();
const {
  openNotificationModal
} = useNotificationModal();

const isSending: Ref<boolean> = ref(false);
const initValues = {
  name: '',
  phone: '',
  message: '',
  email: '',
  file: '',
  url: ''
};
const formValues: any = ref({ ...initValues });
const formValidation: any = {
  name: {
    required: helpers.withMessage('Обязательно к заполнению', required)
  },
  phone: {
    required: helpers.withMessage('Обязательно к заполнению', required),
    minLength: helpers.withMessage('Неверный формат', minLength(17))
  },
  email: {
    email: helpers.withMessage('Неверный формат', email),
    required: helpers.withMessage('Обязательно к заполнению', required)
  },
  message: {
    required: helpers.withMessage('Обязательно к заполнению', required)
  }
};
const $v = useVuelidate(formValidation, formValues);
const changeFormImages = (images: any[]) => {
  formValues.value.file = images;
};
const submitForm = async () => {
  refVCupertino.value.calcFitHeight();

  const isFormCorrect = await $v.value.$validate();
  if (!isFormCorrect) {
    return false;
  }

  // changeLoading(true);
  isSending.value = true;

  const body: any = {
    type: 'Заказать 3Д проект',
    name: formValues.value.name,
    phone: (formValues.value.phone || '').replace(/\D+/g, ''),
    message: (formValues.value?.message || ''),
    email: formValues.value.email,
    url: window.location.href,
    title: 'Заказать 3Д проект',
    no_mail_send: Number(!formValues.value.email)
  };
  if (!formValues.value.email) {
    delete body.email;
  }
  if (!formValues.value.phone) {
    delete body.phone;
  }

  const formData = new FormData();
  Object.keys(body).map((key) => {
    formData.append(key, body[key]);
  });
  (formValues.value.file || []).map((file: any) => {
    formData.append('attachments[]', file);
  });

  const res = await $api.agent.post('/form/send', {
    body: formData
  }).then((res) => res?._data).catch((err) => ({ error: err.response }));
  if (res?.error) {
    isSending.value = false;
    const errorMessage = (res?.error?._data || []).map((t: any) => t.message).join('.<br/>');
    $toast.error(errorMessage || 'Ошибка сервера');
    return;
  }
  isSending.value = false;

  closeCreateOrder3dProjectOpen();
  openNotificationModal({
    title: 'Заказ отправлен',
    message: 'Мы свяжемся с вами в течение 1 дня. Спасибо!'
  });

  if (typeof $ym === 'function') {
    $ym('reachGoal', '3d_project_sent');
  }
};

const onCloseDialog = () => {
  closeCreateOrder3dProjectOpen();
};

const refVCupertino: any = ref(null);
const willPresent = (_refVCupertino: any) => {
  refVCupertino.value = _refVCupertino;
};
</script>

<style lang="scss">
.dialog-create-order {
	.pane {
		max-width: 549px;
		border-radius: 30px;
	}
}

.dialog-create-order__body {
	padding: 26px 40px;
	box-sizing: border-box;
}

.dialog-create-order__title {
	margin-bottom: 32px;
}

.dialog-create-order__form {
	display: flex;
	flex-direction: column;

	& > * {
		margin-top: 17px;

		&:first-child {
			margin-top: 0;
		}
	}

	.--form-element {
	}

	.--form-element .input {
		border-radius: 16px;
		border: 1px solid #EBEBEB;
		padding: 21px 23px;
		height: initial;

		&:focus {
			border-color: #2F2E2E;
		}
	}

	.--form-element .textarea {
		display: block;
		padding: 18px 23px;
		height: 129px;
	}

	.--form-element__error {
		font-size: 12px;
		font-weight: 500;
		color: #ED908C;
	}
}

.dialog-create-order__images {
	margin-top: 24px;

	.--title {
		margin-bottom: 16px;
	}
}

.dialog-create-order__action {
	display: flex;
	flex-direction: column;
	margin-top: 32px;

	.btn {
		width: 100%;
	}

	.--message {
		margin-top: 32px;
		color: #989898;
		text-align: center;
		line-height: 120%;
	}
}

@media (min-width: 1024px) {
	.dialog-create-order {
		padding: 20px 0;
	}
	.dialog-create-order .pane {
		transform: initial !important;
	}
}

@media (max-width: 1023px) {
	.dialog-create-order .pane {
		border-radius: 24px 24px 0 0;
	}
	.dialog-create-order__title {
		font-size: 20px;
		margin-bottom: 20px;
	}
	.dialog-create-order__body {
		padding: 20px;
	}
	.dialog-create-order__form {
		.--form-element .input {
			border-radius: 12px;
			padding: 13px 23px;
		}

		& > * {
			margin-top: 12px;
		}
	}
}
</style>
